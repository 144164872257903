import React from "react";
import Layout from "../layouts/Layout";
import SiteMetadata from "../components/SiteMetaData";
import { BuyMeACoffee } from "../components/Utils";
import Counter from "../components/Counter";
import { Link } from "gatsby";

const ErrorPage = () => {
  const Sidebar = () => {
    return (
      <div>
        <div className="hidden md:contents">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-3 mb-4">
            <div className="text-xl mb-5">Trivia</div>
            <Counter />
          </div>
        </div>
        <BuyMeACoffee />
      </div>
    );
  };
  return (
    <Layout>
      <SiteMetadata title="404" description="Diese Seite existiet nicht" />
      <div className="bg-gray-50 pb-6 flex-grow dark:bg-gray-900 dark:text-white">
        <div className="container">
          <div className="text-center text-4xl py-6 lg:hidden">Error 404</div>
          <div className="md:grid grid-cols-4 gap-3">
            <div className="md:col-span-3 mb-8 md:mb-0">
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden transform p-3 h-full">
                <div className="hidden md:contents">
                  <div className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 mb-2">
                    Error 404
                  </div>
                </div>
                <div>Die Seite existiert nicht.</div>
                <div>
                  Gehe zurück zur{" "}
                  <Link to="/" className="text-blue-500">
                    Startseite.
                  </Link>
                </div>
              </div>
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ErrorPage;
